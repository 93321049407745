import React from 'react'
import IconButton from '@mui/material/IconButton'
import PrintIcon from '@mui/icons-material/Print'
import Tooltip from '@mui/material/Tooltip'

type PrintButtonProps = { handlePrint: () => void; disabled?: boolean }
const PrintButton = ({ handlePrint, disabled }: PrintButtonProps): JSX.Element => (
    <Tooltip title="Print">
        <IconButton sx={{ displayPrint: 'none' }} onClick={handlePrint} disabled={disabled}>
            <PrintIcon />
        </IconButton>
    </Tooltip>
)

export default PrintButton
