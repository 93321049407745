import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import { Typography } from '@mui/material'

export type FilterOption<T extends string> = { type: T; title: string }
export type FilterOnOff<T extends string> = Partial<Record<T, boolean>>

type CardProps<T extends string, U extends FilterOnOff<T>> = {
    cards: FilterOption<T>[]
    activeCards: U
    setActiveCards: Dispatch<SetStateAction<U>>
}

type CalloutProps<T extends string, U extends FilterOnOff<T>> = {
    callouts?: FilterOption<T>[]
    activeCallouts?: U
    setActiveCallouts?: Dispatch<SetStateAction<U>>
}

type FilterMenuProps = CardProps<DTO.ProfileCards, Partial<Record<DTO.ProfileCards, boolean>>> &
    CalloutProps<DTO.ProfileCalloutBoxes, Partial<Record<DTO.ProfileCalloutBoxes, boolean>>> & {
        icon?: typeof ViewColumnIcon
        menuTitle?: string
    }

export const FilterContentMenuForm = ({
    cards,
    activeCards,
    setActiveCards,
    callouts,
    activeCallouts,
    setActiveCallouts,
}: Pick<
    FilterMenuProps,
    'cards' | 'activeCards' | 'setActiveCards' | 'callouts' | 'activeCallouts' | 'setActiveCallouts'
>): JSX.Element => (
    <>
        {callouts && activeCallouts && setActiveCallouts && (
            <Typography marginTop={1} variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Cards
            </Typography>
        )}
        <FormGroup sx={{ paddingX: 1, flexWrap: 'nowrap' }}>
            {cards
                .filter(({ title }) => !!title)
                .map(({ title, type }) => (
                    <FormControlLabel
                        key={type}
                        control={
                            <Switch
                                size="small"
                                checked={activeCards[type]}
                                onChange={(e) =>
                                    setActiveCards({
                                        ...activeCards,
                                        [type]: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={title}
                        sx={{ padding: 1 }}
                    />
                ))}
        </FormGroup>
        <Divider />
        {callouts && activeCallouts && setActiveCallouts && (
            <Typography marginTop={1} variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Callouts
            </Typography>
        )}

        <FormGroup sx={{ paddingX: 1, maxHeight: 400, overflowY: 'auto', flexWrap: 'nowrap' }}>
            {callouts &&
                activeCallouts &&
                setActiveCallouts &&
                callouts
                    .filter(({ title }) => !!title)
                    .map(({ title, type }) => (
                        <FormControlLabel
                            key={type}
                            control={
                                <Switch
                                    size="small"
                                    checked={activeCallouts[type]}
                                    onChange={(e) =>
                                        setActiveCallouts({
                                            ...activeCallouts,
                                            [type]: e.target.checked,
                                        })
                                    }
                                />
                            }
                            label={title}
                            sx={{ padding: 1 }}
                        />
                    ))}
        </FormGroup>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: 1, marginTop: 1 }}>
            <Button
                onClick={() => {
                    setActiveCards(
                        cards.reduce(
                            (acc: FilterOnOff<DTO.ProfileCards>, { type }) => ({ ...acc, [type]: false }),
                            activeCards
                        )
                    )

                    if (callouts && activeCallouts && setActiveCallouts) {
                        setActiveCallouts(
                            callouts.reduce(
                                (acc: FilterOnOff<DTO.ProfileCalloutBoxes>, { type }) => ({ ...acc, [type]: false }),
                                activeCallouts
                            )
                        )
                    }
                }}
                variant="text"
            >
                Hide All
            </Button>
            <Button
                onClick={() => {
                    setActiveCards(
                        cards.reduce(
                            (acc: FilterOnOff<DTO.ProfileCards>, { type }) => ({ ...acc, [type]: true }),
                            activeCards
                        )
                    )

                    if (callouts && activeCallouts && setActiveCallouts) {
                        setActiveCallouts(
                            callouts.reduce(
                                (acc: FilterOnOff<DTO.ProfileCalloutBoxes>, { type }) => ({ ...acc, [type]: true }),
                                activeCallouts
                            )
                        )
                    }
                }}
                variant="text"
            >
                Show All
            </Button>
        </Box>
    </>
)

const FilterContentMenu = ({
    cards,
    activeCards,
    setActiveCards,
    callouts,
    activeCallouts,
    setActiveCallouts,
    icon: Icon = ViewColumnIcon,
    menuTitle = 'Manage Columns',
}: FilterMenuProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title={menuTitle}>
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Icon />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <FilterContentMenuForm
                    callouts={callouts}
                    activeCallouts={activeCallouts}
                    setActiveCallouts={setActiveCallouts}
                    cards={cards}
                    activeCards={activeCards}
                    setActiveCards={setActiveCards}
                />
            </Menu>
        </>
    )
}

export default FilterContentMenu
