import type { Except } from 'type-fest'
import type { ComponentProps } from 'react'
import type { BoxProps } from '@mui/material/Box'
import React, { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Editor from 'react-simple-code-editor'
import { Roboto_Mono } from 'next/font/google'
import { highlight, languages } from 'prismjs/components/prism-core'
import { useWatchVariable, useDebounce } from '@/lib/hooks'
import 'prismjs/themes/prism-okaidia.css' // this is the same theme used on mui.com

const robotoMono = Roboto_Mono({
    weight: ['400'],
    subsets: ['latin'],
    display: 'swap',
})

export const okaidiaBackgroundColor = 'hsl(210, 25%, 9%)'
export const okaidiaTextColor = 'hsl(60, 30%, 96%)'

type EditorProps = ComponentProps<typeof Editor>
export type CodeEditorProps = Except<EditorProps, 'highlight' | 'style'> &
    Pick<BoxProps, 'sx'> & {
        language: string
    }
const CodeEditor = ({
    language,
    sx,
    onValueChange,
    value: initialValue,
    ...editorProps
}: CodeEditorProps): JSX.Element => {
    const editorBoxRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        // focus the editor on mount
        if (editorBoxRef.current) {
            const textArea = editorBoxRef.current.querySelector('textarea')
            textArea?.focus()
        }
    }, [editorBoxRef])

    const [value, setValue] = useState(initialValue)
    const debouncedCodeValue = useDebounce(value)
    useWatchVariable(() => onValueChange(value), debouncedCodeValue)

    return (
        <Box
            ref={editorBoxRef}
            sx={{
                lineHeight: 1.5,
                fontWeight: 400,
                fontSize: 13,
                fontFamily: robotoMono.style.fontFamily,
                '> div': {
                    borderRadius: 3,
                },
                '.pre-sql-code-editor': {
                    color: okaidiaTextColor,
                    minHeight: '14em', // around 8 lines of code
                    // Typically prefer to avoid using !important, but react-simple-code-editor forces our hand here
                    background: `${okaidiaBackgroundColor} !important`,
                },
                '.textarea-sql-code-editor': { caretColor: okaidiaTextColor },
                ...sx,
            }}
        >
            <Editor
                preClassName="pre-sql-code-editor"
                textareaClassName="textarea-sql-code-editor"
                value={value}
                onValueChange={setValue}
                highlight={(code) => highlight(code, languages[language], language)}
                padding={16}
                {...editorProps}
            />
        </Box>
    )
}

export default CodeEditor
