import type { DraftEligiblePlayerFilterParams } from '@/components/draftNight/bigBoard/DraftNightFilters'
import React, { useState, useEffect } from 'react'
import DraftApiContext, { useSubscribeToDraftEntrantChanges } from '@/lib/contexts/DraftApiContext'
import AvailabilitySelectionButton from '@/components/draftNight/bigBoard/AvailabilitySelectionButton'

type BoardPlayerAvailabilityFilterProps = {
    onFilterChange: (playerIdsToFilter: Set<string>) => unknown
    autoRefresh: boolean | undefined
    season: number
    leagueName: Enum.DraftLeague
}

const BoardPlayerAvailabilityButton = ({
    onFilterChange,
    autoRefresh,
    season,
    leagueName,
}: BoardPlayerAvailabilityFilterProps): JSX.Element => {
    const draftEntrants = useSubscribeToDraftEntrantChanges(season, leagueName, !!autoRefresh)
    const [playerAvailabilityToggle, setPlayerAvailabilityToggle] =
        useState<DraftEligiblePlayerFilterParams['undraftedOnly']>(null)

    useEffect(() => {
        if (draftEntrants !== undefined) {
            let playerIdsToFilter: string[]
            if (playerAvailabilityToggle !== null) {
                playerIdsToFilter = (draftEntrants || [])
                    .filter(
                        (d) => !d.available && (playerAvailabilityToggle === 'undrafted' || !d.rank || d.rank <= 30)
                    )
                    .map((d) => d.playerId)
            } else {
                playerIdsToFilter = []
            }
            onFilterChange(new Set(playerIdsToFilter))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftEntrants, playerAvailabilityToggle])

    return (
        <AvailabilitySelectionButton
            abbr
            label={null}
            value={playerAvailabilityToggle}
            onChange={setPlayerAvailabilityToggle}
            sx={{ mr: 2 }}
        />
    )
}

const BoardPlayerAvailabilityFilter = (props: BoardPlayerAvailabilityFilterProps): JSX.Element => (
    <DraftApiContext>
        <BoardPlayerAvailabilityButton {...props} />
    </DraftApiContext>
)

export default BoardPlayerAvailabilityFilter
