import React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export type PrimaryPositionFilterOptions = Partial<
    Record<DTO.PlayerPrimaryPosition | DTO.PlayerSecondaryPosition, true | undefined>
>
type PrimaryPositionFilterProps = {
    selectedPositions: PrimaryPositionFilterOptions
    handlePositionChange: (
        e: React.MouseEvent,
        value: DTO.PlayerPrimaryPosition[] & DTO.PlayerSecondaryPosition[]
    ) => void
    disabled?: boolean
}

const PrimaryPositionFilter = ({
    selectedPositions,
    handlePositionChange,
    disabled,
}: PrimaryPositionFilterProps): JSX.Element => (
    <ToggleButtonGroup
        value={Object.keys(selectedPositions)}
        color="primary"
        onChange={handlePositionChange}
        size="small"
        sx={{ height: '38px' }}
        disabled={disabled}
    >
        <ToggleButton
            sx={{ px: 1.25, minWidth: '44px' }}
            value="ALL"
            selected={Object.keys(selectedPositions).length === 0}
        >
            ALL
        </ToggleButton>
        <ToggleButton sx={{ px: 1.25, minWidth: '44px' }} value="PG" selected={selectedPositions.PG}>
            PG
        </ToggleButton>
        <ToggleButton sx={{ px: 1.25, minWidth: '44px' }} value="SG" selected={selectedPositions.SG}>
            SG
        </ToggleButton>
        <ToggleButton sx={{ px: 1.25, minWidth: '44px' }} value="SF" selected={selectedPositions.SF}>
            SF
        </ToggleButton>
        <ToggleButton sx={{ px: 1.25, minWidth: '44px' }} value="PF" selected={selectedPositions.PF}>
            PF
        </ToggleButton>
        <ToggleButton sx={{ px: 1.25, minWidth: '44px' }} value="C" selected={selectedPositions.C}>
            C
        </ToggleButton>
    </ToggleButtonGroup>
)

export default PrimaryPositionFilter
