import type { Dispatch } from 'react'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'

export type FilterOption<T extends string> = { type: T; title: string }
export type FilterOnOff<T extends string> = Partial<Record<T, boolean>>

type FilterMenuProps<T extends string, U extends FilterOnOff<T>> = {
    columns: FilterOption<T>[]
    activeColumns: U
    setActiveColumns: Dispatch<U>
    icon?: typeof ViewColumnIcon
    menuTitle?: string
}

export const FilterMenuForm = <T extends string, U extends FilterOnOff<T>>({
    columns,
    activeColumns,
    setActiveColumns,
}: Pick<FilterMenuProps<T, U>, 'columns' | 'activeColumns' | 'setActiveColumns'>): JSX.Element => (
    <>
        <FormGroup sx={{ paddingX: 1, maxHeight: 400, overflowY: 'auto', flexWrap: 'nowrap' }}>
            {columns
                .filter(({ title }) => !!title)
                .map(({ title, type }) => (
                    <FormControlLabel
                        key={type}
                        control={
                            <Switch
                                size="small"
                                checked={activeColumns[type]}
                                onChange={(e) =>
                                    setActiveColumns({
                                        ...activeColumns,
                                        [type]: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={title}
                        sx={{ padding: 1 }}
                    />
                ))}
        </FormGroup>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: 1, marginTop: 1 }}>
            <Button
                onClick={() =>
                    setActiveColumns(columns.reduce((acc: U, { type }) => ({ ...acc, [type]: false }), activeColumns))
                }
                variant="text"
            >
                Hide All
            </Button>
            <Button
                onClick={() =>
                    setActiveColumns(columns.reduce((acc: U, { type }) => ({ ...acc, [type]: true }), activeColumns))
                }
                variant="text"
            >
                Show All
            </Button>
        </Box>
    </>
)

const FilterMenu = <T extends string, U extends FilterOnOff<T>>({
    columns,
    activeColumns,
    setActiveColumns,
    icon: Icon = ViewColumnIcon,
    menuTitle = 'Manage Columns',
}: FilterMenuProps<T, U>): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title={menuTitle}>
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Icon />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock
            >
                <FilterMenuForm columns={columns} activeColumns={activeColumns} setActiveColumns={setActiveColumns} />
            </Menu>
        </>
    )
}

export default FilterMenu
