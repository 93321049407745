import type { UpdateBoardParams } from '../../lib/hooks/useBoard'
import { DialogContent } from '@mui/material'
import React from 'react'
import Dialog from '@mui/material/Dialog'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import useToastContext from '../../lib/hooks/useToastContext'
import { useAddTierToBoard } from '../../lib/hooks/useBoard'

type AddTierFormProps = UpdateBoardParams & {
    handleDiscard: () => void
}

const AddTierForm = ({ boardId, version, handleDiscard }: AddTierFormProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutate: submit } = useAddTierToBoard({ boardId, version })

    const formik = useFormik<DTO.AddTierInput>({
        initialValues: {
            name: '',
        },
        onSubmit: (values, { setSubmitting }) => {
            submit(values, {
                onSuccess: () => {
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Saved Changes',
                        duration: 2000,
                    })
                    handleDiscard()
                },
                onError: (error) => {
                    toastContext?.addToast({
                        severity: 'error',
                        message: error.message,
                    })
                    setSubmitting(false)
                },
            })
        },
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
                <DialogTitle>Add Tier</DialogTitle>
                <DialogContent>
                    <Grid item sx={{ paddingTop: 1 }}>
                        <TextField
                            size="small"
                            id="name"
                            name="name"
                            placeholder="Enter Tier Name"
                            label="Name"
                            required
                            fullWidth
                            inputProps={{ maxLength: 250 }}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            autoFocus
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction="row-reverse" spacing={2}>
                        <Grid item>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !formik.dirty}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDiscard} disabled={formik.isSubmitting}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    )
}

type AddTierDialogProps = Omit<AddTierFormProps, 'handleDiscard'> & {
    isOpen: boolean
    handleDialogClose: AddTierFormProps['handleDiscard']
}

const AddTierDialog = ({ isOpen, handleDialogClose, ...rest }: AddTierDialogProps): JSX.Element => (
    <Dialog fullWidth open={isOpen} onClose={handleDialogClose}>
        <AddTierForm handleDiscard={handleDialogClose} {...rest} />
    </Dialog>
)

export default AddTierDialog
