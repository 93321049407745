import type { Except } from 'type-fest'
import React from 'react'
import union from 'lodash.union'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import useToastContext from '../../lib/hooks/useToastContext'
import { useEditPlayerPosition } from '../../lib/hooks/useBoard'
import { positions as defaultPositions } from '../../shared/utils/positions'

type EditPlayerPositionInputProps = {
    player: DTO.BoardPlayer['player']
    boardId: string
    onClose: () => void
}
type InputPlayerPositionForm = Except<DTO.InputPlayerPosition, 'position'> & {
    position: DTO.BoardPlayer['player']['playerPosition']
}

const EditPlayerPositionInput = ({
    player: { playerPosition: position, playerId },
    boardId,
    onClose,
}: EditPlayerPositionInputProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutateAsync } = useEditPlayerPosition(boardId, toastContext)
    const formik = useFormik<InputPlayerPositionForm>({
        initialValues: {
            boardId,
            position,
            playerId,
        },
        onSubmit: async (value) => {
            await mutateAsync(value as DTO.InputPlayerPosition)
            onClose()
        },
    })

    const positionOptions = union(defaultPositions, [position])
    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: 'flex' }}>
                <FormControl size="small" sx={{ minWidth: 70 }}>
                    <Select
                        id={`player-position-${playerId}`}
                        name="position"
                        value={formik.values.position}
                        onChange={formik.handleChange}
                        required
                    >
                        {positionOptions.map((p) => (
                            <MenuItem
                                key={p}
                                value={p}
                                sx={{
                                    fontStyle: defaultPositions.includes(p as DTO.PlayerPrimaryPosition)
                                        ? undefined
                                        : 'italic',
                                }}
                            >
                                {p}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <IconButton size="small" type="submit" disabled={!formik.dirty}>
                    <CheckCircleIcon color={!formik.dirty ? 'disabled' : 'primary'} fontSize="inherit" />
                </IconButton>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon color="inherit" fontSize="inherit" />
                </IconButton>
            </Box>
        </form>
    )
}

export default EditPlayerPositionInput
