import React from 'react'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'

export type FilterOption = { id: string; title: string }

type FilterRankListMenuProps = {
    columns: FilterOption[]
    activeColumns: string[]
    setActiveColumns: (value: string[]) => void
}

export const FilterRankListMenuForm = ({
    columns,
    activeColumns,
    setActiveColumns,
}: FilterRankListMenuProps): JSX.Element => (
    <>
        <FormGroup sx={{ paddingX: 1, maxHeight: 560, overflowY: 'auto', flexWrap: 'nowrap' }}>
            {columns
                .filter(({ title }) => !!title)
                .map(({ title, id }) => (
                    <FormControlLabel
                        key={id}
                        control={
                            <Switch
                                size="small"
                                checked={activeColumns.includes(id)}
                                onChange={() => {
                                    if (activeColumns.includes(id)) {
                                        setActiveColumns(activeColumns.filter((d) => d !== id))
                                    } else {
                                        setActiveColumns([...activeColumns, id])
                                    }
                                }}
                            />
                        }
                        label={title}
                        sx={{ padding: 1 }}
                    />
                ))}
        </FormGroup>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: 1, marginTop: 1 }}>
            <Button onClick={() => setActiveColumns([])} variant="text">
                Hide All
            </Button>
            <Button
                onClick={() => {
                    setActiveColumns(columns.map((obj) => obj.id))
                }}
                variant="text"
            >
                Show All
            </Button>
        </Box>
    </>
)

const FilterRankListMenu = ({ columns, activeColumns, setActiveColumns }: FilterRankListMenuProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title="Manage Rank Columns">
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={!columns.length}
                >
                    <FormatListNumberedIcon />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <FilterRankListMenuForm
                    columns={columns}
                    activeColumns={activeColumns}
                    setActiveColumns={setActiveColumns}
                />
            </Menu>
        </>
    )
}

export default FilterRankListMenu
