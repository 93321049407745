import Dialog from '@mui/material/Dialog'
import React from 'react'
import { useFormik } from 'formik'
import BoardItemNotesForm from './BoardItemNotesForm'
import { useUpdateBoardPlayerNotes } from '@/lib/hooks/useBoard'
import useToastContext from '@/lib/hooks/useToastContext'

type BoardPlayerNotesDialogProps = {
    open: boolean
    initialValues: DTO.BoardPlayer
    setOpen: React.Dispatch<React.SetStateAction<DTO.BoardPlayer | undefined>>
    boardId: string
}

const BoardPlayerNotesDialog = ({
    open,
    initialValues,
    setOpen,
    boardId,
}: BoardPlayerNotesDialogProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutateAsync } = useUpdateBoardPlayerNotes(boardId, initialValues.id, toastContext)

    const formik = useFormik<DTO.BoardPlayer>({
        onSubmit: async (values) => {
            await mutateAsync(values)
            setOpen(undefined)
        },
        initialValues,
    })

    return (
        <Dialog open={open}>
            <BoardItemNotesForm formik={formik} setOpen={setOpen} />
        </Dialog>
    )
}

export default BoardPlayerNotesDialog
