import type TableConfig from '@/lib/types/tableConfigTypes'
import React from 'react'
import Box from '@mui/material/Box'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { SectionText } from '../posts/FormattedText'
import Link from '../Link'
import ReadMore from '../ShowMore'
import Table from '../Table'

export const childTableConfig = (parentRank: number | null, showFullNote: boolean): TableConfig<DTO.ChildItem> => ({
    fields: [
        {
            key: 'scout',
            label: 'Scout',
            // eslint-disable-next-line react/no-unstable-nested-components
            select: (r) => (
                <Link href={`/boards/${r.boardId}`} underline="none">{`${r.graderFirstName} ${r.graderLastName}`}</Link>
            ),
            format: {
                whiteSpace: 'nowrap',
                width: '150px',
            },
            headCellFormat: { backgroundColor: 'inherit' },
        },
        {
            key: 'rank',
            label: 'Rank',
            select: (r) => (r.isRanked ? r.rank : 'UR'),
            format: {
                width: '30px',
            },
            headCellFormat: { backgroundColor: 'inherit' },
        },
        {
            key: 'diff',
            label: 'Diff from Avg',
            // eslint-disable-next-line react/no-unstable-nested-components
            select: (r) => {
                if (!parentRank) return '-'
                const diff = r.rank - parentRank
                return r.isRanked ? (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        {`${Math.abs(diff)}`}{' '}
                        {diff > 0 ? (
                            <ArrowDownwardIcon color="error" fontSize="small" />
                        ) : diff !== 0 ? (
                            <ArrowUpwardIcon color="success" fontSize="small" />
                        ) : null}
                    </Box>
                ) : (
                    '-'
                )
            },
            format: {
                width: '30px',
            },
            headCellFormat: {
                whiteSpace: 'nowrap',
                backgroundColor: 'inherit',
            },
        },
        {
            key: 'notes',
            label: 'Notes',
            // eslint-disable-next-line react/no-unstable-nested-components
            select: (r) =>
                r.notes &&
                (showFullNote ? (
                    <SectionText text={r.notes} marginBottom={false} />
                ) : (
                    <ReadMore characterLength={150}>{r.notes}</ReadMore>
                )),
            headCellFormat: { backgroundColor: 'inherit' },
        },
    ],
    loadingSkeleton: {
        numOfRows: 3,
        height: 50,
    },
    initialSortBy: 'rank',
    initialSortOrder: 'asc',
})

type ChildRankTableProps = {
    overallRank: number | null
    rows: DTO.ChildItem[] | undefined
    autoExpand?: boolean
}
export default ({ overallRank, rows, autoExpand }: ChildRankTableProps): JSX.Element => (
    <Table<DTO.ChildItem>
        rows={rows || []}
        config={childTableConfig(overallRank, !!autoExpand)}
        getRowKey={(item) => `${item.id}-${item.graderId}`}
        hover={false}
    />
)
