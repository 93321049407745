import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import clip from 'text-clipper'
import { SectionText } from './posts/FormattedText'

type ShowMoreProps = {
    text: string
    clippedText: string
}
const ShowMore = ({ text, clippedText }: ShowMoreProps): JSX.Element => {
    const [isShowMore, setIsShowMore] = useState(false)
    return (
        <Box display="inline-block">
            <SectionText text={isShowMore ? text : clippedText} marginBottom={false} display="inline-block" />
            <Button
                variant="text"
                size="small"
                component="span"
                sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                onClick={() => setIsShowMore(!isShowMore)}
            >
                {isShowMore ? 'show less' : 'show more'}
            </Button>
        </Box>
    )
}

type ReadMoreProps = {
    characterLength: number
    children: unknown
    maxLines?: number
}
const ReadMore = ({ characterLength, children: text, maxLines = 3 }: ReadMoreProps): JSX.Element | null => {
    if (typeof text !== 'string') return null

    // check if the string length is longer than the character length
    if (text.length > characterLength) {
        // check if the string length is longer than the character length because of html tags
        // if the clippedHtml is the same as the text, then the text is not clipped and therefore fits the character length
        const clippedHtml = clip(text, characterLength, { html: true, maxLines })
        if (clippedHtml !== text) {
            return <ShowMore text={text} clippedText={clippedHtml} />
        }
    }

    return <SectionText text={text} marginBottom={false} />
}

export default ReadMore
