import type { BoxProps } from '@mui/material/Box'
import type { DraftEligiblePlayerFilterParams } from './DraftNightFilters'
import React, { useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'

type AvailabilitySelectionButtonProps = {
    value: DraftEligiblePlayerFilterParams['undraftedOnly']
    onChange: (value: DraftEligiblePlayerFilterParams['undraftedOnly']) => unknown
    disabled?: boolean
    abbr?: boolean
    label?: string | null
    sx?: BoxProps['sx']
}

const AvailabilitySelectionButton = ({
    disabled,
    abbr,
    value,
    sx,
    onChange: onValueChange,
    label = 'View Selection',
}: AvailabilitySelectionButtonProps): JSX.Element => {
    const onChange = useCallback(
        (e: unknown, v: DraftEligiblePlayerFilterParams['undraftedOnly'] | 'All') =>
            onValueChange(v === 'All' ? null : 'undrafted'),
        [onValueChange]
    )
    return (
        <Box sx={sx}>
            {label && <Typography sx={{ fontSize: '11px', color: 'text.secondary' }}>{label}</Typography>}
            <ToggleButtonGroup
                color="primary"
                onChange={onChange}
                size="small"
                sx={{ height: '38px', backgroundColor: 'common.white' }}
                disabled={disabled}
                exclusive
            >
                <ToggleButton value="All" selected={value === null}>
                    All
                </ToggleButton>
                <ToggleButton value="undrafted" selected={value !== null}>
                    {abbr ? 'Avail.' : 'Available'}
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    )
}

export default AvailabilitySelectionButton
