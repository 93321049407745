import type { EditTierInput, UpdateBoardParams } from '../../lib/hooks/useBoard'
import React from 'react'
import { useFormik } from 'formik'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import useToastContext from '../../lib/hooks/useToastContext'
import { useEditTierName } from '../../lib/hooks/useBoard'

type EditTierNameInputProps = UpdateBoardParams & {
    tier: EditTierInput
    onClose: () => void
}

const EditTierNameInput = ({ boardId, version, tier, onClose }: EditTierNameInputProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutateAsync } = useEditTierName({ boardId, version }, toastContext)

    const formik = useFormik<EditTierInput>({
        initialValues: { ...tier },
        onSubmit: async (value) => {
            await mutateAsync(value)
            onClose()
        },
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <input value={formik.values.name} name="name" onChange={formik.handleChange} required />
            <IconButton size="small" type="submit" disabled={!formik.dirty}>
                <CheckCircleIcon color={!formik.dirty ? 'disabled' : 'primary'} fontSize="inherit" />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
                <CloseIcon color="inherit" fontSize="inherit" />
            </IconButton>
        </form>
    )
}

export default EditTierNameInput
