import type { UpdateBoardParams } from '../../lib/hooks/useBoard'
import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import MultipleSelectSearch from '../search/MultipleSelectSearch'
import { mapTagToSearchResult, mapSearchResultToTag } from '../../lib/utils/search'
import useToastContext from '../../lib/hooks/useToastContext'
import { useAddTeamsToBoard } from '../../lib/hooks/useBoard'

type AddTeamFormProps = UpdateBoardParams & {
    handleDiscard: () => void
    tiers: DTO.BoardTier[]
}

const AddTeamForm = ({ boardId, handleDiscard, tiers, version }: AddTeamFormProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutateAsync: submit } = useAddTeamsToBoard({ boardId, version })

    const getSubmitValues = (formikValues: {
        teams: DTO.PostTag[]
        tier: DTO.BoardTier | null
    }): DTO.AddTeamsInput => ({
        teams: formikValues.teams.map((x) => ({ id: uuidv4(), entityId: x.entityId })),
        tier: formikValues.tier,
    })

    const formik = useFormik<{ teams: DTO.PostTag[]; tier: DTO.BoardTier | null }>({
        initialValues: {
            teams: [],
            tier: null,
        },
        onSubmit: (values) => {
            const submitValues = getSubmitValues(values)
            return submit(submitValues, {
                onSuccess: (resp) => {
                    if (resp.data.warning) {
                        toastContext?.addToast({
                            severity: 'warning',
                            message: resp.data.warning,
                            duration: 2000,
                        })
                    }
                    if (resp.data.message) {
                        toastContext?.addToast({
                            severity: 'success',
                            message: resp.data.message,
                            duration: 2000,
                        })
                    }
                    handleDiscard()
                },
                onError: (error) => {
                    toastContext?.addToast({
                        severity: 'error',
                        message: error.message,
                    })
                },
            })
        },
    })

    const onAboutChange = async (value: DTO.SearchResult[]) => {
        const teams = value.map(mapSearchResultToTag)
        return formik.setFieldValue('teams', teams)
    }

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
                <DialogTitle>Add Teams</DialogTitle>
                <DialogContent>
                    <Grid item sx={{ paddingTop: 1 }}>
                        <MultipleSelectSearch
                            autoFocus
                            searchType="team"
                            value={formik.values.teams.map(mapTagToSearchResult)}
                            onChange={onAboutChange}
                            placeholder="Enter Teams"
                            error={formik.touched.teams && Boolean(formik.errors.teams)}
                            errorText="At least 1 Team is required"
                            required
                        />

                        {tiers.length > 0 && (
                            <FormControl
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                }}
                                size="small"
                            >
                                <InputLabel id="tier-label">Tier</InputLabel>
                                <Select
                                    label="Tier"
                                    name="tier"
                                    multiple={false}
                                    onChange={(val) => {
                                        void formik.setFieldValue('tier', val.target.value)
                                    }}
                                    value={formik.values.tier}
                                >
                                    {tiers.map((d) => (
                                        // @ts-expect-error raw object can be used for value
                                        <MenuItem key={d.id} value={d}>
                                            {d.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction="row-reverse" spacing={2}>
                        <Grid item>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !formik.dirty || formik.values.teams.length === 0}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDiscard} disabled={formik.isSubmitting}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    )
}

type AddTeamDialogProps = Omit<AddTeamFormProps, 'handleDiscard'> & {
    isOpen: boolean
    handleDialogClose: AddTeamFormProps['handleDiscard']
}

const AddTeamDialog = ({ isOpen, handleDialogClose, ...rest }: AddTeamDialogProps): JSX.Element => (
    <Dialog fullWidth open={isOpen} onClose={handleDialogClose}>
        <AddTeamForm handleDiscard={handleDialogClose} {...rest} />
    </Dialog>
)

export default AddTeamDialog
