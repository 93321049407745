import React, { useCallback } from 'react'
import { keyframes } from '@mui/system'
import { teal } from '@mui/material/colors'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import PermissionFeature from './wrappers/PermissionFeature'

type AutoRefreshSwitchProps = {
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
    label?: string
}

const autoRefreshPulse = keyframes`
    to {
        box-shadow: 0 0 4px 2px ${teal[400]};
    }
`

const AutoRefreshSwitch = ({
    checked,
    setChecked,
    label = 'Auto Refresh',
}: AutoRefreshSwitchProps): JSX.Element | null => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked),
        [setChecked]
    )
    return (
        <PermissionFeature types={['auto-refresh']}>
            <FormControlLabel
                label={label}
                control={
                    <Switch
                        size="small"
                        checked={checked}
                        onChange={handleChange}
                        sx={
                            checked
                                ? {
                                      '.MuiSwitch-thumb': {
                                          animation: `${autoRefreshPulse} 1.75s ease infinite alternate-reverse`,
                                      },
                                  }
                                : undefined
                        }
                    />
                }
            />
        </PermissionFeature>
    )
}

export default AutoRefreshSwitch
