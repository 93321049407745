import type { Except } from 'type-fest'
import type TableConfig from '@/lib/types/tableConfigTypes'
import type { TableConfigField } from '@/lib/types/tableConfigTypes'
import type { SortToggleState, SortToggleAction } from '@/lib/hooks/useSortToggle'
import type { DeleteBoardItemFn } from '@/lib/hooks/useBoard'
import type { SystemStyleObject } from '@mui/system'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { getMatchingLOCFromValue } from '../profile/LOCProjections'
import Link from '../Link'
import Controls from '../Controls'
import PermissionFeature from '../wrappers/PermissionFeature'
import TeamLogoAndName from '../TeamLogoAndName'
import PlayerHeadshotAndName from '../PlayerHeadshotAndName'
import ReadMore from '../ShowMore'
import DeleteRowButton from './DeleteRowButton'
import EditPlayerPositionInput from './EditPlayerPositionInput'
import { getLeagueAverageColor, getMeasurementThresholdColor } from '@/lib/utils/colors'
import {
    formatHeight,
    money,
    formatDateString,
    formatMockDraftRange,
    firstInitialLastName,
} from '@/lib/utils/formatters'
import { formatNumber, isNumber } from '@/lib/utils/math'

const firstColumnNames = [
    'height',
    'weight',
    'currentTeam',
    'age',
    'ageOn',
    'position',
    'agent',
    'agency',
    'contractStatus',
    'currentSalary',
    'nextFourSalary',
]
export type BoardSortColumn = Exclude<keyof DTO.BoardPlayer['player'], 'boardRanks'>
// map board column names to player prop names, where they are not the same for sorting
export const boardColToRowPropMap = new Map<BoardColumnsOpts, BoardSortColumn>([
    ['name', 'formalName'],
    ['currentTeam', 'teamAbbr'],
    ['position', 'playerPosition'],
    ['contractStatus', 'contractAmount'],
])

export type BoardColumnsOnOff = {
    rank: boolean
    name: boolean
} & {
    [column in Enum.BoardColumns]?: boolean
}
export type BoardColumnsOpts = keyof BoardColumnsOnOff

export const defaultColumns: BoardColumnsOnOff = {
    rank: true,
    name: true,
}

export interface BoardRankColumn {
    id: string
    title: string
}

export type BoardTableConfig = Except<TableConfig<DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam>, 'fields'> & {
    fields: (Except<TableConfigField<DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam>, 'key'> & {
        key: BoardColumnsOpts | 'dragHandle' | 'moveButton' | 'deleteButton' | 'controlsMenu' | DTO.BoardData['boardId']
    })[]
}

const isMobile = () => window.matchMedia('(max-width: 600px)').matches

export const formatBoardOptionColumn = ({
    r,
    key,
    view,
    measurementPercentiles,
}: {
    r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam
    key: Enum.BoardColumns
    view: Enum.BoardView
    measurementPercentiles?: DTO.MeasurementPercentileMap
}): SystemStyleObject | undefined => {
    if (r.type !== 'player') return {}
    if (key === 'agency' || key === 'agent') {
        return {
            maxWidth: view === 'position' ? '75px' : '170px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    }
    if (key === 'currentTeam' || key === 'mostRecentTeam')
        return {
            maxWidth: view === 'position' ? '50px' : undefined,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    if (
        (key === 'intensityOffPersonal' ||
            key === 'intensityOffRelationships' ||
            key === 'intensityOn' ||
            key === 'intensityMed') &&
        r.player[key]
    ) {
        const colorGrade = [r.player[key]]

        const adjColorGrade = colorGrade.map((color) => {
            switch (color) {
                case 'GREEN':
                    return 'success.light'
                case 'YELLOW':
                    return 'rgb(253, 216, 53)'
                case 'RED':
                    return 'error.light'
                default:
                    return 'black'
            }
        })

        return { color: adjColorGrade[0], fontWeight: 'bold' }
    }
    if (
        (key === 'skillAthleticism' ||
            key === 'skillBbiq' ||
            key === 'skillRebounder' ||
            key === 'skillShooter' ||
            key === 'skillShotCreator' ||
            key === 'skillQuickDecisions' ||
            key === 'skillScorer' ||
            key === 'skillFinisher' ||
            key === 'skillPlaymaker' ||
            key === 'skillRimProtector' ||
            key === 'skillTeamDefender' ||
            key === 'skillIndividualDefense' ||
            key === 'skillPnrDefender' ||
            key === 'skillCompetitiveness') &&
        r.player[key] !== null
    ) {
        return getLeagueAverageColor(r.player[key] as number)
    }

    if ((key === 'height' || key === 'weight') && isNumber(r.player[key])) {
        return getMeasurementThresholdColor(
            key,
            r.player[key],
            r.player.isNBAPlayer ? 'NBA' : 'College',
            r.player.playerSecondaryPosition,
            measurementPercentiles
        )
    }

    return {}
}

type BoardOptionColumnsProps = {
    availableColumns: DTO.BoardColumn[]
    activeColumns: BoardColumnsOnOff
    measurementPercentiles?: DTO.MeasurementPercentileMap
    boardId: string
    setOpenTeamNotesDialog?: React.Dispatch<React.SetStateAction<DTO.BoardTeam | undefined>>
    isEditMode?: boolean
    setEditPositionPlayerId?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer['id'] | undefined>>
    setOpenPlayerNotesDialog?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer | undefined>>
    locValues?: DTO.LOCValue[]
    editPositionPlayerId?: DTO.BoardPlayer['id']
    view: Enum.BoardView
}

export const selectBoardOptionColumn = ({
    r,
    key,
    setOpenTeamNotesDialog,
    isEditMode,
    setEditPositionPlayerId,
    setOpenPlayerNotesDialog,
    locValues,
    editPositionPlayerId,
    boardId,
    view,
}: {
    r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam
    key: Enum.BoardColumns
    setOpenTeamNotesDialog?: BoardOptionColumnsProps['setOpenTeamNotesDialog']
    boardId: BoardOptionColumnsProps['boardId']
    isEditMode?: BoardOptionColumnsProps['isEditMode']
    setEditPositionPlayerId?: BoardOptionColumnsProps['setEditPositionPlayerId']
    setOpenPlayerNotesDialog?: BoardOptionColumnsProps['setOpenPlayerNotesDialog']
    locValues?: BoardOptionColumnsProps['locValues']
    editPositionPlayerId?: BoardOptionColumnsProps['editPositionPlayerId']
    view: BoardOptionColumnsProps['view']
}): string | number | React.JSX.Element | null => {
    if (r.type === 'tier') return null
    if (r.type === 'team') {
        if (key === 'conference') return r.team.conference || '-'
        if (key === 'division') return r.team.division || '-'
        if (key === 'wins') return r.team.wins || '-'
        if (key === 'projectedWins') return isNumber(r.team.projectedWins) ? formatNumber(r.team.projectedWins) : '-'
        if (key === 'teamNotes' && view === 'list') {
            return (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {r.notes && <ReadMore characterLength={150}>{r.notes}</ReadMore>}
                    {setOpenTeamNotesDialog && isEditMode && (
                        <IconButton onClick={() => setOpenTeamNotesDialog(r)} size="small" sx={{ marginLeft: 'auto' }}>
                            <EditIcon color="primary" sx={{ fontSize: '20px' }} />
                        </IconButton>
                    )}
                </Box>
            )
        }
    } else {
        if (key === 'currentTeam')
            return (
                r.player.teamUrlSlug && (
                    <Link
                        href={`/teams/${r.player.teamUrlSlug}/feed`}
                        sx={{ '@media print': { color: 'text.primary' } }}
                    >
                        {r.player.teamAbbr}
                    </Link>
                )
            )
        if (key === 'agent')
            return (
                r.player.agentUrlSlug &&
                r.player.agent && (
                    <Link
                        href={`/agents/${r.player.agentUrlSlug}/feed`}
                        sx={{ '@media print': { color: 'text.primary' } }}
                    >
                        {view === 'position' ? firstInitialLastName(r.player.agent) : r.player.agent}
                    </Link>
                )
            )
        if (key === 'agency')
            return (
                r.player.agencyUrlSlug && (
                    <Link
                        href={`/agencies/${r.player.agencyUrlSlug}/feed`}
                        sx={{
                            '@media print': { color: 'text.primary' },
                        }}
                    >
                        {r.player.agency}
                    </Link>
                )
            )
        if (
            key === 'intensityMed' ||
            key === 'intensityOffPersonal' ||
            key === 'intensityOffRelationships' ||
            key === 'intensityOn'
        ) {
            const intensityString = r.player[key] || ''
            return r.player[key] === null
                ? '-'
                : `${intensityString.charAt(0)}${intensityString.slice(1).toLowerCase()}`
        }

        if (key === 'height') return r.player.height && formatHeight(r.player.height)
        if (key === 'weight') return formatNumber(r.player.weight, 0)
        if (key === 'age' || key === 'ageOn' || key === 'mockDraftPoints')
            return r.player[key] && formatNumber(r.player[key])
        if (key === 'contractStatus')
            return r.player.contractAmount && r.player.contractYears
                ? `${money(r.player.contractAmount, undefined, undefined, true)} / ${r.player.contractYears}`
                : '-'
        if (key === 'currentSalary' || key === 'nextFourSalary')
            return r.player[key] ? `${money(r.player[key] as number, undefined, undefined, true)}` : '-'
        if (
            key === 'locLow' ||
            key === 'locBull' ||
            key === 'locHigh' ||
            key === 'locNow' ||
            key === 'locLowScout' ||
            key === 'locBullScout' ||
            key === 'locHighScout' ||
            key === 'locNowScout'
        ) {
            if (r.player[key] === null) return '-'
            const playerLoc = Number(r.player[key])
            const loc = getMatchingLOCFromValue(playerLoc, locValues)
            if (!loc) return '-'

            const locDiff = playerLoc - loc.value
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return `${loc.abbr!} (${locDiff >= 0 ? '+' : ''}${formatNumber(locDiff) || ''})`
        }

        if (key === 'scoutMostRecentLocs') {
            return r.player[key] === null ? '' : formatDateString(r.player[key], 'M/DD/YY', 'utc')
        }

        if (
            key === 'scoutReportsScout' ||
            key === 'scoutReports' ||
            key === 'intelReportsScout' ||
            key === 'intelReports'
        ) {
            return r.player[key] === null ? 0 : r.player[key]
        }

        if (key === 'rosterLikelihood' || key === 'leagueLikelihood') {
            if (r.player[key] === null) return '-'
            const likelihood = formatNumber(r.player[key] as number, 0, 'percent')
            return likelihood || ''
        }

        if (key === 'position') {
            if (setEditPositionPlayerId && editPositionPlayerId === r.id) {
                return (
                    <EditPlayerPositionInput
                        player={r.player}
                        boardId={boardId}
                        onClose={() => setEditPositionPlayerId(undefined)}
                    />
                )
            }
            return (
                <>
                    {r.player.playerPosition}
                    {setEditPositionPlayerId && isEditMode && (
                        <IconButton size="small" onClick={() => setEditPositionPlayerId(r.id)}>
                            <EditIcon color="primary" fontSize="inherit" />
                        </IconButton>
                    )}
                </>
            )
        }

        if (
            key === 'skillAthleticism' ||
            key === 'skillBbiq' ||
            key === 'skillRebounder' ||
            key === 'skillShooter' ||
            key === 'skillShotCreator' ||
            key === 'skillQuickDecisions' ||
            key === 'skillScorer' ||
            key === 'skillFinisher' ||
            key === 'skillPlaymaker' ||
            key === 'skillRimProtector' ||
            key === 'skillTeamDefender' ||
            key === 'skillIndividualDefense' ||
            key === 'skillPnrDefender' ||
            key === 'skillCompetitiveness'
        ) {
            return r.player[key] === null ? '-' : r.player[key]
        }

        if (key === 'NBAlocationPTNDC' || key === 'GLGlocationPTNDC') {
            return r.player.locationPTNDC || '-'
        }
        if (key === 'NBAlocationTNDC' || key === 'GLGlocationTNDC') {
            return r.player.locationTNDC || '-'
        }
        if (key === 'NBAteamPTNDC' || key === 'GLGteamPTNDC') {
            return r.player.teamPTNDC || '-'
        }
        if (key === 'NBAteamTNDC' || key === 'GLGteamTNDC') {
            return r.player.teamTNDC || '-'
        }

        if (key === 'scoutRegion') {
            const { region, regionScoutSlug, regionScoutName } = r.player

            if (regionScoutSlug && regionScoutName) {
                const n = regionScoutName.split(' ')
                const scoutLastName = n[n.length - 1]

                const scoutPageLink = <Link href={`/users/${regionScoutSlug}/feed`}>{scoutLastName}</Link>
                if (region)
                    return (
                        <>
                            {region} ({scoutPageLink})
                        </>
                    )
                return scoutPageLink
            }
            return region || '-'
        }
        if (
            key === 'scoutReportsScoutAll' ||
            key === 'intelReportsScoutAll' ||
            key === 'scoutReportsAll' ||
            key === 'intelReportsAll' ||
            key === 'mostRecentTeam' ||
            key === 'yearsOfService'
        ) {
            return r.player[key] || '-'
        }
        if (key === 'playerNotes' && view === 'list') {
            return (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {r.notes && <ReadMore characterLength={150}>{r.notes}</ReadMore>}
                    {setOpenPlayerNotesDialog && isEditMode && (
                        <IconButton
                            onClick={() => setOpenPlayerNotesDialog(r)}
                            size="small"
                            sx={{ marginLeft: 'auto' }}
                        >
                            <EditIcon color="primary" sx={{ fontSize: '20px' }} />
                        </IconButton>
                    )}
                </Box>
            )
        }
        if (key === 'mockRange') return formatMockDraftRange(r.player.mockDraftHigh, r.player.mockDraftLow)
    }
    return '-'
}

export const boardOptionColumns = ({
    availableColumns,
    activeColumns,
    measurementPercentiles,
    setOpenTeamNotesDialog,
    isEditMode,
    setEditPositionPlayerId,
    setOpenPlayerNotesDialog,
    locValues,
    editPositionPlayerId,
    boardId,
    view,
}: BoardOptionColumnsProps): BoardTableConfig['fields'] =>
    availableColumns
        .filter(({ type }) => activeColumns[type])
        .map(({ title: label, type: key }) => ({
            label: key === 'yearsOfService' ? 'YOS' : label.replace('Skill - ', ''),
            key,
            skeletonStyle: { xs: 60, md: 100 },
            format: (r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam) =>
                formatBoardOptionColumn({ r, key, view, measurementPercentiles }),
            headCellFormat: { whiteSpace: 'nowrap' },
            select: (r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam) =>
                selectBoardOptionColumn({
                    r,
                    key,
                    setOpenTeamNotesDialog,
                    isEditMode,
                    setEditPositionPlayerId,
                    setOpenPlayerNotesDialog,
                    locValues,
                    editPositionPlayerId,
                    boardId,
                    view,
                }),
            numeric: !!(
                key === 'scoutReports' ||
                key === 'intelReports' ||
                key === 'scoutReportsScout' ||
                key === 'intelReportsScout' ||
                key === 'skillAthleticism' ||
                key === 'skillBbiq' ||
                key === 'skillRebounder' ||
                key === 'skillShooter' ||
                key === 'skillShotCreator' ||
                key === 'skillQuickDecisions' ||
                key === 'skillScorer' ||
                key === 'skillFinisher' ||
                key === 'skillPlaymaker' ||
                key === 'skillRimProtector' ||
                key === 'skillTeamDefender' ||
                key === 'skillIndividualDefense' ||
                key === 'skillPnrDefender' ||
                key === 'skillCompetitiveness' ||
                key === 'wins' ||
                key === 'projectedWins' ||
                key === 'mockDraftPoints' ||
                key === 'mockRange'
            ),
        }))

const tableConfig = (
    boardId: string,
    columns: DTO.BoardColumn[],
    activeColumns: BoardColumnsOnOff,
    rankColumns: BoardRankColumn[],
    activeRankColumns: string[],
    view: Enum.BoardView,
    setOpenLOCDialog?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer['id'] | undefined>>,
    setOpenSkillDialog?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer['id'] | undefined>>,
    setIsContractDialogOpen?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer['id'] | undefined>>,
    handleRemove?: DeleteBoardItemFn,
    isEditMode?: boolean,
    locValues?: DTO.LOCValue[],
    isRanked = true,
    editPositionPlayerId?: DTO.BoardPlayer['id'],
    setEditPositionPlayerId?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer['id'] | undefined>>,
    showHeadshot?: boolean,
    boardSortState?: SortToggleState<BoardColumnsOpts>,
    setBoardSortState?: SortToggleAction<BoardColumnsOpts>,
    measurementPercentiles?: DTO.MeasurementPercentileMap,
    setOpenPlayerNotesDialog?: React.Dispatch<React.SetStateAction<DTO.BoardPlayer | undefined>>,
    setOpenTeamNotesDialog?: React.Dispatch<React.SetStateAction<DTO.BoardTeam | undefined>>,
    isChildBoard?: boolean,
    isParentBoard?: boolean,
    handleUnrankedClick?: (itemId: string) => Promise<unknown>
): BoardTableConfig => {
    const firstColumns = columns.filter(({ type }) => firstColumnNames.includes(type))
    const lastColumns = columns.filter(({ type }) => !firstColumnNames.includes(type))

    const rankColumn: BoardTableConfig['fields'] = isRanked
        ? [
              {
                  label: `${isParentBoard ? 'Avg Rank' : 'Rank'}`,
                  key: 'rank',
                  skeletonStyle: { xs: 60 },
                  format: (_, tableProps) => ({
                      fontWeight: 'medium',
                      width: '30px',
                      position: 'sticky',
                      zIndex: 1,
                      left: isEditMode ? 88 : 0,
                      backgroundColor: tableProps.isDragging ? 'transparent' : 'common.white',
                      '@media print': {
                          position: 'initial',
                          background: 'transparent',
                          fontSize: '18px',
                      },
                  }),
                  headCellFormat: () => ({
                      backgroundColor: 'common.white',
                      position: 'sticky',
                      zIndex: 5,
                      left: isEditMode ? 88 : 0,
                      '@media print': {
                          position: 'initial',
                          background: 'transparent',
                      },
                      whiteSpace: 'nowrap',
                  }),
                  select: (r) => {
                      if (r.type === 'player' || r.type === 'team') {
                          if (isChildBoard && !r.isRanked && handleUnrankedClick && isEditMode) {
                              return (
                                  <Chip
                                      label="Unranked"
                                      variant="filled"
                                      size="small"
                                      color="warning"
                                      sx={{
                                          '&:hover:after': {
                                              backgroundColor: 'green',
                                              content: "'Mark Ranked'",
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              height: '24px',
                                              borderRadius: '16px',
                                              whiteSpace: 'nowrap',
                                              position: 'relative',
                                              width: '100px',
                                              left: -8,
                                              padding: '0 14px',
                                              display: 'flex',
                                              flexDirection: 'row',
                                          },
                                          width: '100px',
                                      }}
                                      onClick={() => handleUnrankedClick(r.id)}
                                  />
                              )
                          }
                          if (isChildBoard && !r.isRanked) {
                              return (
                                  <Chip
                                      label="Unranked"
                                      variant="filled"
                                      size="small"
                                      color="warning"
                                      sx={{
                                          width: '100px',
                                      }}
                                  />
                              )
                          }
                          return r.rank
                      }
                      return null
                  },
                  numeric: true,
              },
          ]
        : []

    const editColumns: BoardTableConfig['fields'] = isEditMode
        ? [
              {
                  label: '',
                  key: 'dragHandle',
                  skeletonStyle: { xs: 0 },
                  format: {
                      position: 'sticky',
                      zIndex: 1,
                      left: 0,
                  },
                  headCellFormat: { backgroundColor: 'common.white', position: 'sticky', zIndex: 5, left: 0 },
              },
              {
                  label: '',
                  key: 'moveButton',
                  skeletonStyle: { xs: 0 },
                  format: {
                      position: 'sticky',
                      zIndex: 1,
                      left: 56,
                  },
                  headCellFormat: { backgroundColor: 'common.white', position: 'sticky', zIndex: 5, left: 56 },
              },
          ]
        : []

    const parentDropdownCol: BoardTableConfig['fields'] = isParentBoard
        ? [
              {
                  label: '',
                  key: 'parentDropdown',
                  skeletonStyle: { xs: 0 },
                  format: {
                      position: 'sticky',
                      zIndex: 1,
                      left: 0,
                      '@media print': {
                          display: 'none',
                      },
                  },
                  headCellFormat: {
                      backgroundColor: 'common.white',
                      position: 'sticky',
                      zIndex: 5,
                      left: 0,
                      '@media print': { display: 'none' },
                  },
              },
          ]
        : []

    const deleteColumn: BoardTableConfig['fields'] = isEditMode
        ? [
              {
                  label: '',
                  key: 'deleteButton',
                  skeletonStyle: { xs: 60 },
                  format: {
                      position: 'sticky',
                      zIndex: 1,
                      paddingRight: 0,
                      right: 0,
                      textAlign: 'right',
                  },
                  select: (r, _, tableProps) => {
                      if (r.type === 'tier') return null
                      return (
                          handleRemove &&
                          !isChildBoard && (
                              <DeleteRowButton
                                  id={r.id}
                                  position={r.position}
                                  handleRemove={handleRemove}
                                  isDragging={tableProps.isDragging ? tableProps.isDragging : false}
                              />
                          )
                      )
                  },
              },
          ]
        : []

    const controlsMenu: BoardTableConfig['fields'] = !isEditMode
        ? [
              {
                  label: '',
                  key: 'controlsMenu',
                  format: {
                      visibility: { xs: 'hidden', sm: 'visible' },
                      position: 'sticky',
                      zIndex: 1,
                      paddingRight: 0,
                      right: 0,
                      textAlign: 'right',
                      backgroundColor: 'white',
                      displayPrint: 'none',
                  },
                  select: (r) => {
                      if (r.type !== 'player') return null
                      return (
                          <Controls>
                              <MenuItem onClick={() => setOpenLOCDialog?.(r.id)}>Add LOCs</MenuItem>
                              <MenuItem onClick={() => setOpenSkillDialog?.(r.id)}>Add Skills</MenuItem>
                              <PermissionFeature type="contracts">
                                  {r.player.level === 'PRO' && (
                                      <MenuItem onClick={() => setIsContractDialogOpen?.(r.id)}>View Contract</MenuItem>
                                  )}
                              </PermissionFeature>
                          </Controls>
                      )
                  },
              },
          ]
        : []

    const parentColumns: BoardTableConfig['fields'] = isParentBoard
        ? [
              {
                  label: 'Range',
                  key: 'range',
                  select: (r) => {
                      if (r.type === 'tier') return null
                      const { rankRange, rankRangeRatio } = r
                      return (
                          <>
                              {!!rankRange && `${rankRange[0]}-${rankRange[1]}`}
                              {!!rankRangeRatio &&
                                  rankRangeRatio[0] !== rankRangeRatio[1] &&
                                  ` [${rankRangeRatio[0]}/${rankRangeRatio[1]}]`}
                          </>
                      )
                  },
                  format: { whiteSpace: 'nowrap' },
              },
          ]
        : []

    const boardRankOptionColumns = rankColumns
        .filter(({ id }) => activeRankColumns.includes(id))
        .map(({ title: label, id: key }) => ({
            label,
            key,
            skeletonStyle: { xs: 60, md: 100 },
            format: (r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam) => {
                if (r.type !== 'player') return {}
                return {}
            },
            select: (r: DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam) => {
                if (r.type !== 'player') return null
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return !r.player.boardRanks?.[key] ? '-' : r.player.boardRanks[key]
            },
            numeric: true,
        }))

    return {
        size: 'medium',
        loadingSkeleton: {
            height: 800,
            numOfRows: 17,
        },
        sortState: boardSortState as SortToggleState<string>,
        setSortState: setBoardSortState as SortToggleAction<string>,
        fields: [
            ...editColumns,
            ...parentDropdownCol,
            ...rankColumn,
            {
                label: 'Name',
                key: 'name',
                skeletonStyle: { xs: 150, md: 200 },

                format: (_, tableProps) => ({
                    position: 'sticky',
                    zIndex: 1,
                    left: isEditMode ? (isRanked ? 152 : 88) : isRanked ? 64 : 0,
                    backgroundColor: tableProps.isDragging ? 'transparent' : 'common.white',
                    paddingLeft: '20px',
                    minWidth: isMobile() ? 0 : 200,
                    whiteSpace: 'nowrap',
                    borderRight: '1px solid',
                    borderRightColor: 'divider',
                    '@media print': {
                        position: 'initial',
                        background: 'transparent',
                        borderRight: 'none',
                    },
                }),
                headCellFormat: {
                    backgroundColor: 'common.white',
                    borderRight: '1px solid',
                    borderRightColor: 'divider',
                    position: 'sticky',
                    zIndex: 5,
                    left: isEditMode ? (isRanked ? 152 : 88) : isRanked ? 64 : 0,
                    '@media print': {
                        position: 'initial',
                        background: 'transparent',
                        borderRight: 'none',
                    },
                },
                select: (r) => {
                    if (r.type === 'tier') return null
                    if (r.type === 'team')
                        return (
                            <TeamLogoAndName
                                teamName={r.team.teamName}
                                teamLogo={r.team.teamLogoUrl}
                                teamSlug={r.team.urlSlug}
                                teamAbbr={r.team.teamAbbreviation}
                                teamLogoHeight={40}
                                teamLogoWidth={40}
                            />
                        )
                    return (
                        <PlayerHeadshotAndName
                            showHeadshot={showHeadshot}
                            playerUrlSlug={r.player.playerUrlSlug}
                            name={r.name}
                            lastName={r.player.lastName}
                            imageUrl={r.player.imageUrl}
                            imageWidth={40}
                            imageHeight={40}
                        />
                    )
                },
            },
            ...parentColumns,
            ...boardOptionColumns({
                availableColumns: firstColumns,
                activeColumns,
                measurementPercentiles,
                boardId,
                setOpenTeamNotesDialog,
                isEditMode,
                setEditPositionPlayerId,
                setOpenPlayerNotesDialog,
                locValues,
                editPositionPlayerId,
                view,
            }),
            ...boardRankOptionColumns,
            ...boardOptionColumns({
                availableColumns: lastColumns,
                activeColumns,
                measurementPercentiles,
                boardId,
                setOpenTeamNotesDialog,
                isEditMode,
                setEditPositionPlayerId,
                setOpenPlayerNotesDialog,
                locValues,
                editPositionPlayerId,
                view,
            }),
            ...controlsMenu,
            ...deleteColumn,
        ],
    }
}

export default tableConfig
