import type { useFormik } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import { isSubmitButtonDisabled } from '../form/formUtils'
import RichText from '../form/RichText/Editor'

type BoardItemNotesFormProps = {
    formik: ReturnType<typeof useFormik<DTO.BoardPlayer>> | ReturnType<typeof useFormik<DTO.BoardTeam>>
    setOpen:
        | React.Dispatch<React.SetStateAction<DTO.BoardPlayer | undefined>>
        | React.Dispatch<React.SetStateAction<DTO.BoardTeam | undefined>>
}

const BoardItemNotesForm = ({ formik, setOpen }: BoardItemNotesFormProps): JSX.Element => (
    <form noValidate onSubmit={formik.handleSubmit}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent>
            <Box paddingTop={1}>
                <RichText
                    id="notes"
                    initialValue={formik.values.notes || undefined}
                    placeholder="Enter Notes..."
                    label="Notes"
                    onChange={(text) => {
                        void formik.setFieldValue('notes', text)
                    }}
                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                    minHeight={100}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                variant="text"
                onClick={() => {
                    setOpen(undefined)
                    formik.resetForm()
                }}
                disabled={formik.isSubmitting}
            >
                Cancel
            </Button>
            <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                disabled={isSubmitButtonDisabled({
                    isSubmitting: formik.isSubmitting,
                    dirty: formik.dirty,
                    isValid: formik.isValid,
                    submitCount: formik.submitCount,
                })}
            >
                Save Changes
            </LoadingButton>
        </DialogActions>
    </form>
)

export default BoardItemNotesForm
