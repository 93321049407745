import React, { useCallback, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useUpdateBoardCompleteStatus } from '@/lib/hooks/useBoards'

type BoardCompleteToggleProps = DTO.BoardCompletionStatus

const BoardCompleteToggle = ({ boardId, isComplete, version }: BoardCompleteToggleProps): JSX.Element => {
    const { mutateAsync } = useUpdateBoardCompleteStatus()

    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const onConfirm = useCallback(
        async (complete: boolean) => {
            setIsLoading(true)
            await mutateAsync({ boardId, isComplete: complete, version })
            setOpen(false)
            setIsLoading(false)
        },
        [boardId, version, mutateAsync]
    )

    return (
        <Stack
            direction="row"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
        >
            <Typography variant="subtitle1" alignItems="center">
                Status:
            </Typography>
            <ToggleButtonGroup size="small" color={isComplete ? 'success' : 'warning'} value={isComplete}>
                <ToggleButton
                    value
                    sx={{ px: 1.25 }}
                    selected={isComplete}
                    size="small"
                    color={isComplete ? 'success' : 'primary'}
                    onClick={() => setOpen(true)}
                    disabled={isComplete}
                >
                    Complete
                </ToggleButton>
                <ToggleButton
                    value={false}
                    sx={{ px: 1.25 }}
                    selected={!isComplete}
                    size="small"
                    color={!isComplete ? 'warning' : 'primary'}
                    onClick={() => setOpen(true)}
                    disabled={!isComplete}
                >
                    Incomplete
                </ToggleButton>
            </ToggleButtonGroup>
            <Dialog
                sx={{ '& .MuiDialog-paper': { minWidth: 250, maxHeight: 435 } }}
                maxWidth="xs"
                open={open}
                keepMounted
            >
                <DialogContent>
                    <Typography variant="body1" fontWeight="medium">
                        Mark Board as {isComplete ? 'Incomplete' : 'Complete'}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: 'text.secondary' }} autoFocus onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{ color: 'primary.main' }}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => onConfirm(!isComplete)}
                    >
                        Mark as {isComplete ? 'Incomplete' : 'Complete'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default BoardCompleteToggle
