import type { RequireExactlyOne, Simplify, SetOptional, SetRequired } from 'type-fest'
import type { ProfileContentPlayer } from '@/lib/hooks/useProfileContent'
import React, { useCallback, useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { forEachLimit } from 'modern-async'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { useMergePages, usePrintProfile, useSetupPrintJob } from '../../lib/hooks/useBulkPrint'
import useToastContext from '../../lib/hooks/useToastContext'
import { useProfileContent } from '../../lib/hooks'
import { useConstantsContext } from '../../lib/contexts/ConstantsContext'
import { FilterContentMenuForm } from './FilterContentMenu'
import { formatDateString } from '@/lib/utils/formatters'

export type PrintPlayer = Simplify<
    SetOptional<SetRequired<ProfileContentPlayer, 'urlSlug'>, 'finalSeason'> & Pick<DTO.Player, 'formalName'>
>
type PrintProps = RequireExactlyOne<
    {
        level: Enum.BoardLevels | Enum.PlayerLevel
        season?: number
        player?: SetRequired<PrintPlayer, 'finalSeason'>
        players?: PrintPlayer[]
        title?: string
    },
    'player' | 'players'
>

const batchSize = 15
const maxRetry = 2

const PrintProfileButton = ({
    players: playerListProp,
    player: playerProp,
    title,
    level,
    season,
}: PrintProps): JSX.Element => {
    const players = useMemo(() => (playerProp ? [playerProp] : playerListProp), [playerProp, playerListProp])
    const { mutateAsync: printProfile } = usePrintProfile()
    const { mutateAsync: setupPrintJob } = useSetupPrintJob()
    const { mutate: mergePDFs } = useMergePages()

    const [progress, setProgress] = useState(0)
    const [isLoaderVisible, setIsLoaderVisible] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const toastContext = useToastContext()

    const profileLevel: DTO.Player['level'] =
        level === 'Amateur' ? 'AM' : level === 'Pro' || level === 'G-League' ? 'PRO' : level
    const { profileYear } = useConstantsContext()
    const [cards, activeCards, setActiveCards, callouts, activeCallouts, setActiveCallouts] = useProfileContent(
        profileLevel,
        playerProp || {
            isNBAPlayer: profileLevel === 'PRO',
            finalSeason: `${profileYear}-${(profileYear + 1).toString().substring(2)}`,
        }
    )

    const onPrint = useCallback(
        async (zip: boolean) => {
            const folder = uuidv4()
            setIsLoaderVisible(true)
            setProgress(0)

            const totalJobs = players.length + 1
            await setupPrintJob({ level: profileLevel })
            setProgress(100 / totalJobs)

            const activeCardKeys = Object.entries(activeCards)
                .filter(([, selected]) => selected)
                .map(([key]) => key as DTO.ProfileCards)

            const activeCalloutKeys = Object.entries(activeCallouts)
                .filter(([, selected]) => selected)
                .map(([key]) => key as DTO.ProfileCalloutBoxes)

            const succeeded: PrintPlayer[] = []
            const failed: PrintPlayer[] = []
            const processProfile = async (player: PrintPlayer) => {
                const { urlSlug: playerSlug, formalName } = player
                try {
                    const res = await printProfile({
                        playerSlug,
                        formalName,
                        folder,
                        cards: activeCardKeys,
                        calloutBoxes: activeCalloutKeys,
                        season,
                        level: profileLevel,
                    })
                    if (res.status === 200 || res.status === 206) {
                        setProgress(((1 + succeeded.length) / totalJobs) * 100)
                        if (res.status === 200) succeeded.push(player)
                    } else {
                        failed.push(player)
                    }
                } catch {
                    failed.push(player)
                }
            }
            // call all profiles in parallel up to batch size / concurrency limit
            await forEachLimit(players, processProfile, batchSize)
            // retry failed profiles
            for (let i = 0; i < maxRetry; i += 1) {
                if (failed.length) {
                    const retry = [...failed]
                    failed.length = 0 // reset failed array
                    // eslint-disable-next-line no-await-in-loop
                    await forEachLimit(retry, processProfile, batchSize)
                }
            }

            if (succeeded.length) {
                if (failed.length) {
                    toastContext?.addToast({
                        severity: 'error',
                        message: `Failed to Load ${failed.length} Profile${failed.length === 1 ? '' : 's'}`,
                    })
                }

                const files = succeeded.map(({ formalName }) => `profiles/${folder}/${formalName}.pdf`)

                setProgress(-1)
                mergePDFs(
                    {
                        files,
                        folder,
                        zip,
                        title: `PlayerProfiles ${formatDateString(undefined, 'YYYY-MM-DD', 'eastern')}`,
                        type: 'playerProfile',
                    },
                    {
                        onSuccess: () => {
                            setIsLoaderVisible(false)
                            setIsDialogOpen(false)
                            toastContext?.addToast({
                                severity: 'success',
                                message: 'File(s) Saved',
                            })
                        },
                    }
                )
            } else {
                setIsLoaderVisible(false)
                toastContext?.addToast({
                    severity: 'error',
                    message: 'Error Loading PDFs',
                })
            }

            return 'Complete'
        },
        [
            players,
            setupPrintJob,
            profileLevel,
            season,
            activeCards,
            activeCallouts,
            printProfile,
            mergePDFs,
            toastContext,
        ]
    )

    return (
        <>
            {title && (
                <Button onClick={() => setIsDialogOpen(true)} size="medium" disabled={isLoaderVisible}>
                    {title}
                </Button>
            )}
            {!title && (
                <IconButton onClick={() => setIsDialogOpen(true)} size="medium" disabled={isLoaderVisible}>
                    <PictureAsPdfIcon sx={{ color: 'text.secondary' }} fontSize="inherit" />
                </IconButton>
            )}
            <Dialog fullWidth={!!isLoaderVisible} open={isDialogOpen}>
                <DialogTitle sx={{ textAlign: isLoaderVisible ? 'left' : 'center' }}>
                    {!isLoaderVisible
                        ? `Print ${players.length} Profile${players.length === 1 ? '' : 's'}`
                        : progress === -1
                        ? 'Building File...'
                        : 'Loading Profiles...'}
                </DialogTitle>
                <DialogContent>
                    {isDialogOpen && !isLoaderVisible && (
                        <DialogActions>
                            <Grid container columnSpacing={2} sx={{ width: '350px' }}>
                                <Grid xs={12} item sx={{ mb: 3 }}>
                                    <FilterContentMenuForm
                                        cards={cards}
                                        activeCards={activeCards}
                                        setActiveCards={setActiveCards}
                                        callouts={callouts}
                                        activeCallouts={activeCallouts}
                                        setActiveCallouts={setActiveCallouts}
                                    />
                                </Grid>

                                <Grid xs={4} item>
                                    <Button
                                        sx={{ width: '100%' }}
                                        variant="outlined"
                                        onClick={() => setIsDialogOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid xs={4} item>
                                    <Button
                                        sx={{ width: '100%' }}
                                        variant="contained"
                                        onClick={() => onPrint(true)}
                                        disabled={!Object.values(activeCards).some(Boolean)}
                                    >
                                        ZIP
                                    </Button>
                                </Grid>
                                <Grid xs={4} item>
                                    <Button
                                        sx={{ width: '100%' }}
                                        variant="contained"
                                        onClick={() => onPrint(false)}
                                        disabled={!Object.values(activeCards).some(Boolean)}
                                    >
                                        PDF
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    )}
                    {isLoaderVisible && (
                        <LinearProgress variant={progress === -1 ? 'indeterminate' : 'determinate'} value={progress} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PrintProfileButton
