import { Paper, Typography } from '@mui/material'
import React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import PeopleIcon from '@mui/icons-material/People'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import { ascending } from 'd3-array'

type GradersMenuProps = {
    allGraders: DTO.BoardGrader[]
    setSelectedGraders: React.Dispatch<React.SetStateAction<Map<number, boolean>>>
    selectedGraders: Map<number, boolean>
}

const GradersMenu = ({ allGraders, setSelectedGraders, selectedGraders }: GradersMenuProps): JSX.Element => {
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)
    const sortedAllGraders = allGraders.sort((a, b) => ascending(a.name, b.name))

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, graderId: number) => {
        const newSelectedGraders = new Map(selectedGraders)
        newSelectedGraders.set(graderId, event.target.checked)
        setSelectedGraders(newSelectedGraders)
    }

    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{ displayPrint: 'none' }}
            >
                <PeopleIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 100 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper sx={{ padding: 2 }}>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <FormControl component="fieldset" variant="standard" autoFocus={open}>
                                    <FormLabel component="legend">Toggle Graders</FormLabel>
                                    <FormGroup>
                                        {sortedAllGraders.map((grader) => {
                                            const completeLabel = (
                                                <Stack direction="row">
                                                    {grader.name}
                                                    <Typography
                                                        color={grader.isComplete ? 'green' : 'orange'}
                                                        marginLeft={1}
                                                    >
                                                        {grader.isComplete ? '(Complete)' : '(Incomplete)'}
                                                    </Typography>
                                                </Stack>
                                            )
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={selectedGraders.get(grader.id)}
                                                            onChange={(e) => handleChange(e, grader.id)}
                                                            name={grader.name}
                                                        />
                                                    }
                                                    label={completeLabel}
                                                    key={grader.id}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default GradersMenu
