import { Box } from '@mui/system'
import React from 'react'
import PlayerHeadshotAvatar from './boards/PositionalBoard/PlayerHeadshotAvatar'
import Link from '@/components/Link'
import { useBreakPoints } from '@/lib/hooks'

type PlayerHeadshotAndNameProps = {
    playerUrlSlug: string
    name: string
    lastName: string
    imageUrl: string | null
    showHeadshot?: boolean
    imageHeight?: number
    imageWidth?: number
}

const PlayerHeadshotAndName = ({
    showHeadshot = true,
    playerUrlSlug,
    name,
    lastName,
    imageUrl,
    imageHeight,
    imageWidth,
}: PlayerHeadshotAndNameProps): JSX.Element => {
    const { isMobile } = useBreakPoints()
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: showHeadshot ? 1 : undefined,
            }}
        >
            {showHeadshot !== false && !isMobile && (
                <PlayerHeadshotAvatar height={imageHeight} width={imageWidth} imageUrl={imageUrl} outlined />
            )}
            <Link
                sx={{ '@media print': { color: 'text.primary', fontSize: '18px' } }}
                href={`/players/${playerUrlSlug}/feed`}
            >
                {isMobile ? `${name[0]}. ${lastName || ''}` : name}
            </Link>
        </Box>
    )
}

export default PlayerHeadshotAndName
