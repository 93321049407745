import type { Url } from 'next/dist/shared/lib/router/router'
import Box from '@mui/material/Box'
import React from 'react'
import TeamLogo from './stats/StatsTeamLogo'
import Link from './Link'
import { useBreakPoints } from '@/lib/hooks'

type HREF = 'feed' | 'team-draft-assets' | 'schedule' | 'stats'
type TeamLogoAndNameProps = {
    teamName: string | null | undefined
    teamLogo: string | null | undefined
    teamSlug: string | null | undefined
    teamAbbr: string | null | undefined
    teamId?: string | null
    leagueName?: Enum.League
    isWidget?: boolean
    teamLogoHeight?: number
    teamLogoWidth?: number
    abbreviate?: boolean
    opacity?: number
    href?: HREF
    gap?: number
    fontSize?: number
    bold?: boolean
}

const TeamLogoAndName = ({
    teamName,
    teamLogo,
    teamSlug,
    teamAbbr,
    teamId,
    leagueName,
    isWidget,
    teamLogoHeight,
    teamLogoWidth,
    href = 'feed',
    abbreviate = false,
    opacity = 1,
    gap = 1,
    fontSize,
    bold = false,
}: TeamLogoAndNameProps): JSX.Element => {
    const { isMobile } = useBreakPoints()

    let hrefUrl: Url | undefined
    if (teamId && leagueName && href === 'team-draft-assets')
        hrefUrl = `/leagues/${leagueName}/draft/team-draft-assets?teamId=${teamId}`
    else if (teamSlug && href === 'stats') hrefUrl = `/teams/${teamSlug}/${href}/overview`
    else if (teamSlug) hrefUrl = `/teams/${teamSlug}/${href}`

    const shortName = teamName && teamName.length > 5 ? `${teamName.substring(0, 5)}...` : teamName
    const name = isMobile || isWidget || abbreviate ? teamAbbr || shortName : teamName
    return (
        <Box
            gap={gap}
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: bold ? 'bold' : 'normal',
            }}
        >
            <TeamLogo url={teamLogo} name={null} height={teamLogoHeight} width={teamLogoWidth} opacity={opacity} />
            {hrefUrl ? (
                <Link href={hrefUrl} sx={{ opacity }} fontSize={fontSize} color="text.primary">
                    {name}
                </Link>
            ) : (
                name
            )}
        </Box>
    )
}

export default TeamLogoAndName
