import type { Except } from 'type-fest'
import type { CodeEditorProps } from './CodeEditor'
import React from 'react'
import CodeEditor from './CodeEditor'
// import SQL language support
import 'prismjs/components/prism-sql'

type SQLCodeEditorProps = Except<CodeEditorProps, 'language'>

const SQLCodeEditor = (props: SQLCodeEditorProps): JSX.Element => <CodeEditor language="sql" {...props} />

export default SQLCodeEditor
